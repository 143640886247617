@use "../../utils/variables";

.redbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    background-color: variables.$traditional-red;

    .svg-inline--fa {
        color: white;
        opacity: 0.7;
        height: 20px;
    }
    .svg-inline--fa:not(:last-child) {
        margin-right: 25px;
    }
    .svg-inline--fa:hover {
        cursor: pointer;
        opacity: 1;
        transition: 0.3s ease-in-out;
    }
}
 
@media screen and (min-width: 900px) {

    .redbar {
        justify-content: flex-end;
        padding-right: 8rem;
        height: 40px;
        //background-image: linear-gradient(variables.$traditional-red, #e00000df, variables.$traditional-red);
    }
}
